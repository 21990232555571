import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import Fade from "react-reveal/Fade"

const Bold = ({ children }) => (
  <span className="font-sans font-bold">{children}</span>
)
const Text = ({ children }) => (
  <Fade>
    <p className="w-full mt-2 mb-4 font-sans text-lg leading-8 text-left text-gray-100">
      {children}
    </p>
  </Fade>
)
const website_url = "https://www.soletchegaray.com.ar"
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.CODE]: (embedded) => (
      <div>
        <div dangerouslySetInnerHTML={{ __html: embedded }} />
      </div>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node) => {
      return (
        <a
          href={node.data.uri}
          className="inline-block pb-0 font-bold border-b border-green-300 hover:bg-green-600 hover:text-white"
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
        >
          {node.content[0].value}
        </a>
      )
    },
    [BLOCKS.PARAGRAPH]: (_, children) => <Text>{children}</Text>,
  },
}

export default ({ FormatText }) => (
  <Format>{documentToReactComponents(FormatText.json, options)}</Format>
)

const Format = styled.div`
  ${tw`relative `}
`
